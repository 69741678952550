<template>
  <div class="container fill">
    <div class="row">
      <div class="col-4 col-md-3 col-lg-3">
        <PropertyStepper :property="property" :landlord="landlord" />
      </div>
      <div class="col-4 col-md-5 col-lg-6">
        <Title class="mb-1" path="property.documents.title" />
        <LandlordDocumentsForm class="mb-1" v-model="landlordDocuments" />
        <PropertyDocumentsForm v-model="propertyDocuments" :id="id" :property="property" />
      </div>
    </div>
  </div>
  <Teleport to="#userLayoutFooter" v-if="landlord && property">
    <PropertyStepper mobile :property="property" :landlord="landlord" />
    <OnboardingFooter @continue="onContinue" @exit="onExit" :step="5" :isLoading="loading" />
  </Teleport>
</template>
<script lang="ts" setup>
import { LandlordDocumentsForm, OnboardingFooter, PropertyDocumentsForm, PropertyStepper, Title } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { findCurrentLandlord, updateCurrentLandlordDocuments } from '@/modules/landlord/application'
import type { Landlord } from '@/modules/landlord/domain/landlord'
import type { LandlordDocuments } from '@/modules/landlord/domain/landlordDocuments'
import { findPropertyById, updatePropertyDocuments } from '@/modules/property/application'
import type { Property } from '@/modules/property/domain/property'
import type { PropertyDocuments } from '@/modules/property/domain/propertyDocuments'
import { router } from '@/router'
import { onMounted, ref } from 'vue'

const id = router.currentRoute.value.params.id as string
const landlord = ref<Landlord | undefined>()
const landlordDocuments = ref<LandlordDocuments | undefined>()
const property = ref<Property | undefined>()
const propertyDocuments = ref<PropertyDocuments | undefined>()
const loading = ref(false)

const { track } = useAnalytics()

track(AnalyticsEvent.LandlordStartDocumentation)

onMounted(async () => {
  landlord.value = await findCurrentLandlord()
  landlordDocuments.value = { ...landlord.value.documents }
  property.value = await findPropertyById(id)
  propertyDocuments.value = { ...property.value.documents }
})

async function save() {
  if (!propertyDocuments.value || !landlordDocuments.value) return

  loading.value = true
  try {
    await updateCurrentLandlordDocuments(landlordDocuments.value)
    await updatePropertyDocuments(id, propertyDocuments.value)
    track(AnalyticsEvent.LandlordSubmitDocumentation)
  } catch {}
  loading.value = false
}

async function onExit() {
  await save()
  router.push({ name: 'properties' })
}

async function onContinue() {
  await save()
  router.push({ name: 'paymentInformation' })
}
</script>
