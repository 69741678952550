<template>
  <Modal @close="onClose">
    <div class="flex align-center justify-center column gap-1 p-4 primary">
      <img src="@/assets/images/congratulations.png" />
      <h2>{{ t('listings.requestConfirmation.title') }}</h2>
      <p>{{ t('listings.requestConfirmation.description') }}</p>
      <p>
        <b>{{ t('listings.requestConfirmation.question') }}</b>
      </p>
      <img src="@/assets/images/curvedArrow.png" height="70" />
    </div>
    <div class="flex align-center justify-center column gap-1 p-3">
      <Button @click="onContinue">{{ t('common.continue') }}</Button>
      <a @click="onClose"> {{ t('common.cancel') }} </a>
    </div>
  </Modal>
</template>
<script setup lang="ts">
import { Button, Modal } from '@/components'
import { createApplication } from '@/modules/application/application'
import type { Listing } from '@/modules/listing/domain/listing'
import { router } from '@/router'
import { useI18n } from 'vue-i18n'

const props = defineProps<{ listing: Listing }>()
const emit = defineEmits(['close'])

const { t } = useI18n()

async function onContinue() {
  const id = await createApplication(props.listing.propertyId)
  router.push({ name: 'tenantsInformation', params: { id } })
}

function onClose() {
  emit('close')
}
</script>
