<template>
  <Modal v-if="isModalOpened" mandatory>
    <div class="top flex align-center justify-center column gap-1 primary">
      <img src="@/assets/images/congratulations.png" />
      <h2>{{ t('tenant.delete.title') }}</h2>
      <p class="pl-2 pr-2">
        <b>{{ t('tenant.delete.bold') }}</b>
        {{ t('tenant.delete.description') }}
      </p>
      <img src="@/assets/images/curvedArrow.png" height="70" />
    </div>
    <div class="flex align-center justify-center column gap-1 p-2">
      <Loader v-if="loading" />
      <template v-else>
        <Button @click="isModalOpened = false">
          {{ t('tenant.delete.cancel') }}
        </Button>
        <a @click="onDelete">{{ t('tenant.delete.confirm') }}</a>
      </template>
    </div>
  </Modal>
  <Button
    class="small"
    variant="secondary"
    :icon="deleteIcon"
    @click="isModalOpened = true"
    data-test-id="deleteTenantButton"
  >
    {{ t('common.delete') }}
  </Button>
</template>
<script lang="ts" setup>
import deleteIcon from '@/assets/images/icons/delete.svg'
import { Button, Loader, Modal } from '@/components'
import { deleteTenant } from '@/modules/tenant/application'
import type { Tenant } from '@/modules/tenant/domain/tenant'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{ tenant: Tenant }>()

const emit = defineEmits(['deleted'])

const { t } = useI18n()

const loading = ref(false)
const isModalOpened = ref(false)

async function onDelete() {
  loading.value = true
  if (props.tenant.id) {
    await deleteTenant(props.tenant.id)
  }
  emit('deleted')
  isModalOpened.value = false
  loading.value = false
}
</script>
<style lang="sass" scoped>
.top
  padding: 5rem
  padding-bottom: 2rem

  @media screen and (max-width: 768px)
    padding: 4rem 2rem
</style>
