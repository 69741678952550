import type { Application } from '../domain/application'

export interface ApplicationApiDto {
  id: string
  propertyId: string
  property: {
    address: {
      street: string
    }
    details?: {
      nBedrooms: number
      parking: Nullable<boolean>
      size: { built: number }
    }
    files: {
      path: string
      type: string
    }[]
    rent?: {
      amount: number
    }
    title: Nullable<string>
  }
}

export function fromDto(dto: ApplicationApiDto): Application {
  const images = dto.property.files.filter(({ type }) => type === 'property-image')
  const image = images[0]?.path ? `${import.meta.env.VITE_FILE_STORAGE_BASE_URL}/files/${images[0].path}` : null

  return {
    bedrooms: dto.property.details?.nBedrooms || 0,
    hasParking: Boolean(dto.property.details?.parking),
    id: dto.id,
    image,
    price: dto.property.rent?.amount || 0,
    propertyId: dto.propertyId,
    size: dto.property.details?.size.built ?? 0,
    street: dto.property.address.street,
    title: dto.property.title
  }
}
