<template>
  <div class="flex column gap-2" v-if="model">
    <Title path="property.payment.title" />
    <div data-test-id="paymentPlanSelector">
      <PricingPlanOption
        :plan="PricingPlan.Secured"
        :selected="model.plan === PricingPlan.Secured"
        @select="onSelect(PricingPlan.Secured)"
      />
      <PricingPlanOption
        :plan="PricingPlan.PeaceOfMind"
        :selected="model.plan === PricingPlan.PeaceOfMind"
        @select="onSelect(PricingPlan.PeaceOfMind)"
        popular
      />
    </div>
  </div>
  <FormSkeleton v-else />
</template>
<script lang="ts" setup>
import { FormSkeleton, Title } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { PricingPlan, type PropertyPaymentInformation } from '@/modules/property/domain/propertyPaymentInformation'
import PricingPlanOption from './PricingPlanOption.vue'

const model = defineModel<PropertyPaymentInformation>()

const { track } = useAnalytics()

function onSelect(plan: PricingPlan) {
  if (!model.value) return

  model.value.plan = plan
  track(AnalyticsEvent.LandlordPropertyRegisterSelectPlan)
}
</script>
