export enum UserType {
  Landlord = 'landlord',
  Tenant = 'tenant'
}

export interface User {
  email: string
  id: string
  name: Nullable<string>
  phone: Nullable<string>
  surname: Nullable<string>
  type: UserType
}

export function isLandlord(userType: UserType): userType is UserType.Landlord {
  return userType === UserType.Landlord
}

export function isTenant(userType: UserType): userType is UserType.Tenant {
  return userType === UserType.Tenant
}
