import { i18n } from '@/i18n'
import type { AxiosInstance } from 'axios'
import type { ApplicationRepository } from '../domain/applicationRepository'
import { fromDto, type ApplicationApiDto } from './apiDto'

export function apiApplicationRepositoryBuilder(axios: AxiosInstance): ApplicationRepository {
  return {
    async create(userId: string, propertyId: string) {
      const { id } = (await axios.post('/property-applications', { tenantId: userId, propertyId })) as ApplicationApiDto
      return id
    },
    async getAll(userId: string) {
      const dtos = (await axios.get(`/tenants/${userId}/applications`)) as ApplicationApiDto[]
      return dtos.map(fromDto)
    },
    async submit(userId: string) {
      const lang = i18n.global.locale.value
      await axios.post(`/property-applications/submit?lang=${lang}`, { tenantId: userId })
    }
  }
}
