import { authService } from '@/modules/auth'
import { tenantRepository, updateTenantFormValidator } from '../infrastructure'
import { createBuilder } from './create'
import { deleteBuilder } from './delete'
import { deleteDocumentBuilder } from './deleteDocument'
import { findAllBuilder } from './findAll'
import { findLeadBuilder } from './findLead'
import { updateBuilder } from './update'
import { updateDocumentsBuilder } from './updateDocuments'

export const createTenant = createBuilder(tenantRepository, authService)
export const deleteTenant = deleteBuilder(tenantRepository, authService)
export const deleteTenantDocument = deleteDocumentBuilder(tenantRepository, authService)
export const findAllTenants = findAllBuilder(tenantRepository, authService)
export const findTenantLead = findLeadBuilder(tenantRepository, authService)
export const updateTenant = updateBuilder(tenantRepository, authService, updateTenantFormValidator)
export const updateTenantDocuments = updateDocumentsBuilder(tenantRepository, authService)
