<template>
  <InputField
    v-model="model.name"
    :error="errors['name']"
    :label="`* ${t('forms.name')}`"
    :placeholder="t('forms.name')"
    type="text"
    data-test-id="nameInput"
  />
  <InputField
    v-model="model.surname"
    :error="errors['surname']"
    :label="`* ${t('forms.surname')}`"
    :placeholder="t('forms.surname')"
    type="text"
    data-test-id="surnameInput"
  />
  <template v-if="!collapsed">
    <InputField
      :disabled="disabledEmail"
      name="email"
      v-model="model.email"
      :error="errors['email']"
      :label="`* ${t('forms.email')}`"
      :placeholder="t('forms.email')"
      type="email"
      data-test-id="emailInput"
    />
    <PhoneInputField
      v-model="model.phone"
      :error="errors['phone']"
      :label="`* ${t('forms.phone')}`"
      :placeholder="t('forms.phone')"
      data-test-id="phoneInput"
    />
    <p class="title">* {{ t('tenant.employmentStatus') }}</p>
    <RadioButton
      v-model="model.employmentStatus"
      :options="employmentStatusOptions"
      name="employmentStatus"
      data-test-id="employmentStatusSelector"
    />
  </template>
</template>
<script lang="ts" setup>
import { InputField, PhoneInputField, RadioButton } from '@/components'
import type { Tenant } from '@/modules/tenant/domain/tenant'
import { TenantEmploymentStatus } from '@/modules/tenant/domain/tenantEmploymentStatus'
import { useI18n } from 'vue-i18n'

const model = defineModel<Tenant>({ required: true })

defineProps<{ collapsed: boolean; errors: Record<string, string>; disabledEmail: boolean }>()

const { t } = useI18n()

const employmentStatusOptions = Object.values(TenantEmploymentStatus).map((value) => ({
  value,
  label: `tenant.employmentStatuses.${value}`
}))
</script>
