import type { AxiosInstance } from 'axios'
import type { TenantRepository } from '../domain/tenantRepository'
import type { UpdateTenantForm } from '../domain/updateTenantForm'
import { fromDto, fromFileDto, type SubtenantApiDto, type TenantApiDto, type TenantDocumentDto } from './apiDto'
import type { TenantDocuments } from '../domain/tenantDocuments'
import type { Document } from '@/modules/shared/domain/document/document'

export function apiTenantRepositoryBuilder(apiService: AxiosInstance, apiV2Service: AxiosInstance): TenantRepository {
  async function updateDocuments(
    userId: string,
    documents: TenantDocuments,
    subtenantId?: string
  ): Promise<TenantDocuments> {
    const employmentContract = await uploadDocuments(
      userId,
      'employment-contract',
      documents.employmentContract,
      subtenantId
    )
    const id = await uploadDocuments(userId, 'id', documents.id, subtenantId)
    const payslip = await uploadDocuments(userId, 'payslip', documents.payslip, subtenantId)
    const lastForm100 = await uploadDocuments(userId, 'payslip', documents.lastForm100, subtenantId, 'last_form_100')
    const lastForm300 = await uploadDocuments(userId, 'payslip', documents.lastForm300, subtenantId, 'last_form_300')
    const pensionRevaluationCertificate = await uploadDocuments(
      userId,
      'payslip',
      documents.pensionRevaluationCertificate,
      subtenantId,
      'pension_revaluation_certificate'
    )

    return {
      employmentContract,
      id,
      lastForm100,
      lastForm300,
      payslip,
      pensionRevaluationCertificate
    }
  }

  async function uploadDocuments(
    userId: string,
    type: string,
    documents: Document[],
    subtenantId?: string,
    namePrefix?: string
  ): Promise<Document[]> {
    const newDocuments: Document[] = []
    const existingDocuments: Document[] = []
    const filesToUpload: File[] = []

    documents.forEach((file) => {
      if (file.source) {
        newDocuments.push(file)
        const fileData = file.source as File
        const name = namePrefix ? `${namePrefix}_${fileData.name}` : fileData.name
        filesToUpload.push(new File([fileData], name, { type: fileData.type }))
      } else {
        existingDocuments.push(file)
      }
    })

    if (filesToUpload.length > 0) {
      const formData = new FormData()
      filesToUpload.forEach((fileData) => formData.append('files[]', fileData))

      const endpoint = subtenantId
        ? `/tenants/${userId}/sub-tenants/${subtenantId}/documents/${type}`
        : `/tenants/${userId}/documents/${type}`
      const dto = (await apiService.post(endpoint, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })) as TenantDocumentDto[]

      return [...existingDocuments, ...dto.map(fromFileDto)]
    } else {
      return documents
    }
  }

  return {
    async create(userId: string) {
      const { id } = (await apiService.post(`/tenants/${userId}/sub-tenants`)) as SubtenantApiDto
      return id.toString()
    },
    async delete(userId: string, tenantId: string) {
      return apiService.delete(`/tenants/${userId}/sub-tenants/${tenantId}`)
    },
    async deleteDocument(userId: string, tenantId: string, documentId: string) {
      if (userId === tenantId) {
        return apiService.delete(`/tenants/${userId}/documents/${documentId}`)
      } else {
        return apiService.delete(`/tenants/${userId}/sub-tenants/${tenantId}/documents/${documentId}`)
      }
    },
    async getAll(userId: string) {
      const dto = (await apiService.get(`/tenants/${userId}`)) as TenantApiDto
      const subtenantQueries = dto.subTenants.map(async (subTenant) => {
        const subTenantDto = (await apiService.get(`/tenants/${userId}/sub-tenants/${subTenant.id}`)) as SubtenantApiDto
        return subTenantDto
      })
      const subtenantDtos = await Promise.all(subtenantQueries)

      return fromDto({ ...dto, subTenants: subtenantDtos })
    },
    async getLead() {
      return apiV2Service.get('/leads/tenants/me')
    },
    async update(userId: string, tenantId: string, form: UpdateTenantForm) {
      const { email, employmentStatus, name, phone, surname } = form

      if (userId === tenantId) {
        await apiService.patch(`/tenants/${userId}`, { employmentStatus, name, phone, surname })
      } else {
        await apiService.patch(`/tenants/${userId}/sub-tenants/${tenantId}`, {
          email,
          employmentStatus,
          name: surname ? `${name} ${surname}` : name,
          phone,
          status: 'inProgress'
        })
      }
    },
    async updateDocuments(userId: string, tenantId: string, documents: TenantDocuments) {
      if (userId === tenantId) {
        return updateDocuments(userId, documents)
      } else {
        return updateDocuments(userId, documents, tenantId)
      }
    }
  }
}
