<template>
  <div class="container fill">
    <div class="row">
      <div class="col-4 col-md-3 col-lg-3">
        <PropertyStepper :property="property" :landlord="landlord" />
      </div>
      <div class="col-4 col-md-5 col-lg-6">
        <BasicInformationForm v-model="basicInformation" />
      </div>
    </div>
  </div>
  <Teleport to="#userLayoutFooter" v-if="landlord && property">
    <PropertyStepper mobile :property="property" :landlord="landlord" />
    <OnboardingFooter @continue="onContinue" @exit="onExit" :step="2" :isLoading="loading" />
  </Teleport>
</template>
<script lang="ts" setup>
import { BasicInformationForm, OnboardingFooter, PropertyStepper } from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { findCurrentLandlord } from '@/modules/landlord/application'
import type { Landlord } from '@/modules/landlord/domain/landlord'
import { findPropertyById, updatePropertyBasicInformation } from '@/modules/property/application'
import type { Property } from '@/modules/property/domain/property'
import type { PropertyBasicInformation } from '@/modules/property/domain/propertyBasicInformation'
import { router } from '@/router'
import { onMounted, ref } from 'vue'

const { track } = useAnalytics()

const id = router.currentRoute.value.params.id as string
const landlord = ref<Landlord | undefined>()
const property = ref<Property | undefined>()
const basicInformation = ref<PropertyBasicInformation | undefined>()
const loading = ref(false)

track(AnalyticsEvent.LandlordPropertyRegisterStartGeneralData)

onMounted(async () => {
  landlord.value = await findCurrentLandlord()
  property.value = await findPropertyById(id)
  basicInformation.value = { ...property.value.basicInformation }
})

async function save() {
  if (!basicInformation.value) return

  loading.value = true
  try {
    await updatePropertyBasicInformation(id, basicInformation.value)
    track(AnalyticsEvent.LandlordPropertyRegisterSubmitGeneralData)
  } catch {}
  loading.value = false
}

async function onExit() {
  await save()
  router.push({ name: 'properties' })
}

async function onContinue() {
  await save()
  router.push({ name: 'equipmentInformation' })
}
</script>
