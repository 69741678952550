import type { Property } from '../domain/property'
import type { PropertyBasicInformation, PropertyCondition, PropertyType } from '../domain/propertyBasicInformation'
import { AvailabilityType, CountryState, type PropertyCoreInformation } from '../domain/propertyCoreInformation'
import type { PropertyDocuments } from '../domain/propertyDocuments'
import {
  OutdoorExtra,
  type IndoorExtra,
  type PropertyEquipmentInformation
} from '../domain/propertyEquipmentInformation'
import type { PropertyImages } from '../domain/propertyImages'
import { PricingPlan } from '../domain/propertyPaymentInformation'

export interface PropertyApiDto {
  address: {
    street: Nullable<string>
    floor: Nullable<string>
  }
  catastralRef: string
  details: {
    balcony: Nullable<boolean>
    equipment: string[]
    furniture: Nullable<boolean>
    hasEquipment: Nullable<boolean>
    lift: Nullable<boolean>
    parking: Nullable<boolean>
    propertyCondition: Nullable<string>
    propertyType: Nullable<string>
    nBathrooms: Nullable<number>
    nBedrooms: Nullable<number>
    pets: Nullable<boolean>
    size: { built: Nullable<number>; usable: Nullable<number> }
    storageRoom: Nullable<boolean>
    terrace: Nullable<boolean>
  }
  files: Nullable<{ container: string; id: string; originalName: string; path: string; uuid: string; type: string }[]>
  id: string
  listed: boolean
  management: {
    availabilityDate: Nullable<string>
    availabilityType: Nullable<string>
    needsPhotoService: Nullable<boolean>
  }
  rent: {
    amount: number
  }
  status: string
}

export interface PricingPlanDto {
  pricingPlan: { id: string }
}

export function fromPricingPlanDto(dto: PricingPlanDto) {
  if (dto.pricingPlan.id === '1b61bc70-8be3-4a99-a957-5d1fdc7a6d42') {
    return PricingPlan.Secured
  } else if (dto.pricingPlan.id === '35ffbaa3-c37c-4d21-947a-9cc52d205b7f') {
    return PricingPlan.PeaceOfMind
  } else {
    return null
  }
}

export function toPricingPlanDto(paymentPlan: PricingPlan): string {
  if (paymentPlan === PricingPlan.Secured) {
    return '1b61bc70-8be3-4a99-a957-5d1fdc7a6d42'
  } else {
    return '35ffbaa3-c37c-4d21-947a-9cc52d205b7f'
  }
}

export function propertyApiDtoToPropertyCoreInformation(
  dto: PropertyApiDto,
  state: Nullable<CountryState>
): PropertyCoreInformation {
  return {
    availabilityDate: dto.management.availabilityDate,
    availabilityType: dto.management.availabilityType as AvailabilityType,
    builtSize: dto.details.size.built,
    catastralReference: dto.catastralRef === '' ? null : dto.catastralRef,
    floor: dto.address.floor,
    rent: dto.rent.amount === 0 ? null : dto.rent.amount,
    street: dto.address.street,
    state,
    usableSize: dto.details.size.usable
  }
}

export function propertyCoreInformationToDto(coreInformation: PropertyCoreInformation): Record<string, unknown> {
  return {
    address: {
      floor: coreInformation.floor,
      street: coreInformation.street
    },
    catastralRef: coreInformation.catastralReference ?? '',
    details: {
      size: {
        built: coreInformation.builtSize,
        usable: coreInformation.usableSize
      }
    },
    management: {
      availabilityDate:
        coreInformation.availabilityType === AvailabilityType.ExactDate ? coreInformation.availabilityDate : undefined,
      availabilityType:
        coreInformation.availabilityType === AvailabilityType.ExactDate && coreInformation.availabilityDate === null
          ? null
          : coreInformation.availabilityType
    },
    rent: {
      amount: coreInformation.rent ?? 0
    }
  }
}

export function propertyApiDtoToPropertyBasicInformation(dto: PropertyApiDto): PropertyBasicInformation {
  return {
    bathrooms: dto.details.nBathrooms,
    bedrooms: dto.details.nBedrooms,
    pets: dto.details.pets,
    propertyCondition: dto.details.propertyCondition as Nullable<PropertyCondition>,
    propertyType: dto.details.propertyType as Nullable<PropertyType>
  }
}

export function propertyBasicInformationToDto(basicInformation: PropertyBasicInformation): Record<string, unknown> {
  return {
    details: {
      nBathrooms: basicInformation.bathrooms,
      nBedrooms: basicInformation.bedrooms,
      pets: basicInformation.pets,
      propertyCondition: basicInformation.propertyCondition,
      propertyType: basicInformation.propertyType
    }
  }
}

export function propertyApiDtoToPropertyEquipmentInformation(dto: PropertyApiDto): PropertyEquipmentInformation {
  const outdoorExtras: OutdoorExtra[] = []
  if (dto.details.balcony) outdoorExtras.push(OutdoorExtra.Balcony)
  if (dto.details.lift) outdoorExtras.push(OutdoorExtra.Lift)
  if (dto.details.parking) outdoorExtras.push(OutdoorExtra.PrivateGarage)
  if (dto.details.storageRoom) outdoorExtras.push(OutdoorExtra.StorageRoom)
  if (dto.details.terrace) outdoorExtras.push(OutdoorExtra.Terrace)

  return {
    furniture: dto.details.furniture,
    indoorExtras: dto.details.equipment as IndoorExtra[],
    outdoorExtras
  }
}

export function propertyEquipmentInformationToDto(
  equipmentInformation: PropertyEquipmentInformation
): Record<string, unknown> {
  return {
    details: {
      balcony: equipmentInformation.outdoorExtras.includes(OutdoorExtra.Balcony),
      equipment: equipmentInformation.indoorExtras,
      furniture: equipmentInformation.furniture,
      hasEquipment: equipmentInformation.indoorExtras.length > 0,
      lift: equipmentInformation.outdoorExtras.includes(OutdoorExtra.Lift),
      parking: equipmentInformation.outdoorExtras.includes(OutdoorExtra.PrivateGarage),
      storageRoom: equipmentInformation.outdoorExtras.includes(OutdoorExtra.StorageRoom),
      terrace: equipmentInformation.outdoorExtras.includes(OutdoorExtra.Terrace)
    }
  }
}

export function propertyApiDtoToPropertyImages(dto: PropertyApiDto): PropertyImages {
  return {
    needsPhotoService: dto.management.needsPhotoService,
    files:
      dto.files
        ?.filter(({ type }) => type === 'property-image')
        .map(({ container, uuid, path, originalName }) => ({
          name: originalName,
          id: uuid,
          uri: `${import.meta.env.VITE_FILE_STORAGE_BASE_URL}/${container}/${path}`,
          source: null
        })) ?? []
  }
}

export function propertyApiDtoToPropertyDocuments(dto: PropertyApiDto): PropertyDocuments {
  return {
    energyCertificate:
      dto.files
        ?.filter(({ type }) => type === 'energy-certificate')
        .map(({ id, originalName }) => ({ name: originalName, id, uri: null, source: null })) ?? [],
    habitabilityCertificate:
      dto.files
        ?.filter(({ type }) => type === 'habitability-certificate')
        .map(({ id, originalName }) => ({ name: originalName, id, uri: null, source: null })) ?? [],
    propertyExpenses:
      dto.files
        ?.filter(({ type }) => type === 'property-expense-bill')
        .map(({ id, originalName }) => ({ name: originalName, id, uri: null, source: null })) ?? [],
    utilityBill:
      dto.files
        ?.filter(({ type }) => type === 'utility-bill')
        .map(({ id, originalName }) => ({ name: originalName, id, uri: null, source: null })) ?? []
  }
}

export function fromDto(
  dto: PropertyApiDto,
  paymentPlan: Nullable<PricingPlan>,
  state: Nullable<CountryState>
): Property {
  return {
    basicInformation: propertyApiDtoToPropertyBasicInformation(dto),
    coreInformation: propertyApiDtoToPropertyCoreInformation(dto, state),
    equipmentInformation: propertyApiDtoToPropertyEquipmentInformation(dto),
    documents: propertyApiDtoToPropertyDocuments(dto),
    id: dto.id,
    listed: dto.status === 'onboardingCompleted',
    images: propertyApiDtoToPropertyImages(dto),
    paymentInformation: { plan: paymentPlan }
  }
}
