<template>
  <Stepper :title="t('property.onboarding.title')" :mobile="mobile">
    <StepperItem
      link="coreInformation"
      :name="t('property.core.step')"
      :done="isCoreInformationDone"
      :active="active === 'coreInformation'"
    />
    <StepperItem
      :name="t('property.characteristics.step')"
      :done="isCharacteristicsDone"
      :active="isCharacteristicsActive"
      :children="[
        {
          name: t('property.basic.step'),
          done: isBasicInformationDone,
          link: 'basicInformation',
          active: active === 'basicInformation'
        },
        {
          name: t('property.equipment.step'),
          done: isEquipmentInformationDone,
          link: 'equipmentInformation',
          active: active === 'equipmentInformation'
        },
        {
          name: t('property.images.step'),
          done: isImagesDone,
          link: 'propertyPhotos',
          active: active === 'propertyPhotos'
        }
      ]"
    />
    <StepperItem
      scroll
      link="propertyDocuments"
      :name="t('property.documents.step')"
      :done="isDocumentsDone"
      :active="active === 'propertyDocuments'"
      :children="[...landlordDocumentsItems, ...propertyDocumentsItems]"
    />
    <StepperItem
      link="paymentInformation"
      :name="t('property.payment.step')"
      :done="false"
      :active="active === 'paymentInformation'"
    />
  </Stepper>
</template>
<script lang="ts" setup>
import { StepperItem, Stepper } from '@/components'
import type { Landlord } from '@/modules/landlord/domain/landlord'
import { hasLandlordDocuments, type LandlordDocuments } from '@/modules/landlord/domain/landlordDocuments'
import {
  hasBasicInformation,
  hasCoreInformation,
  hasEquipmentInformation,
  hasPropertyDocuments,
  hasPropertyImages,
  requiredPropertyDocumentTypes,
  type Property
} from '@/modules/property/domain/property'
import { router } from '@/router'
import { computed, ref } from 'vue'
import { useI18n } from 'vue-i18n'

const props = defineProps<{ property?: Property; landlord?: Landlord; mobile?: boolean }>()

const { t } = useI18n()

const active = router.currentRoute.value.name

const landlordDocumentTypes: (keyof LandlordDocuments)[] = ['id']
const isCoreInformationDone = computed(() => props.property && hasCoreInformation(props.property))
const isBasicInformationDone = computed(() => props.property && hasBasicInformation(props.property))
const isEquipmentInformationDone = computed(() => props.property && hasEquipmentInformation(props.property))
const isImagesDone = computed(() => props.property && hasPropertyImages(props.property))
const isDocumentsDone = computed(
  () => props.property && props.landlord && hasPropertyDocuments(props.property) && hasLandlordDocuments(props.landlord)
)
const isCharacteristicsDone = computed(
  () => isBasicInformationDone.value && isEquipmentInformationDone.value && isImagesDone.value
)
const isCharacteristicsActive = computed(
  () => active === 'basicInformation' || active === 'equipmentInformation' || active === 'propertyPhotos'
)

const landlordDocumentsItems = computed(() =>
  landlordDocumentTypes.map((type) => ({
    name: t(`landlord.documents.type.${type}`),
    done: props.landlord ? props.landlord.documents[type].length > 0 : false,
    link: `#${type}`,
    active: false
  }))
)

const propertyDocumentsItems = computed(() => {
  if (!props.property) return []

  return requiredPropertyDocumentTypes(props.property).map((type) => ({
    name: t(`property.documents.type.${type}`),
    done: props.property ? props.property.documents[type].length > 0 : false,
    link: `#${type}`,
    active: false
  }))
})
</script>
