<template>
  <footer class="footer">
    <div class="button-container">
      <Loader v-if="isLoading" />
      <template v-else-if="isCompleted">
        <Button @click="onContinue" data-test-id="continueOnboardingButton">
          {{ t('property.onboarding.finish') }}
        </Button>
      </template>
      <template v-else>
        <p class="exit-text" @click="onExit">{{ t('property.onboarding.saveAndExit') }}</p>
        <Button variant="secondary" :disabled="disabled" @click="onContinue" data-test-id="continueOnboardingButton">
          {{ t('property.onboarding.continue') }}
        </Button>
      </template>
    </div>
  </footer>
</template>
<script setup lang="ts">
import { Button, Loader } from '@/components'
import { useI18n } from 'vue-i18n'

defineProps<{
  step: number
  disabled?: boolean
  isLoading?: boolean
  isCompleted?: boolean
}>()

const emit = defineEmits(['continue', 'exit'])

const { t } = useI18n()

function onContinue() {
  emit('continue')
}

function onExit() {
  emit('exit')
}
</script>
<style lang="scss" scoped>
.footer {
  border-top: 1px solid $grey200;
  padding: $paddingS 0;
  width: 100%;
  background-color: $brandLight400;
  position: relative;
  z-index: 7;
}

.button-container {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
  margin: 0 $paddingS;
  padding: 0 $paddingXL;
  height: 3.25rem;

  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
    height: auto;
    gap: 1rem;
  }
}

.exit-text {
  font-size: 16px;
  color: $grey500;
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
