<template>
  <ApplicationUpdatedModal />
  <template v-if="applications">
    <TenantAccountSuccessModal v-if="applications.length > 0" :application="applications[0]" />
    <TenantEmptyAccountModal v-else />
  </template>
  <div class="main">
    <div class="container">
      <h1 class="mb-2">{{ t('applications.myApplications') }}</h1>
      <hr />
      <div class="flex gap-1 wrap mt-2">
        <template v-if="applications">
          <EmptyApplications v-if="applications.length === 0" />
          <ImageCard v-for="application in applications" :source="application.image" data-test-id="applicationCard">
            <p class="mb-05">
              <span v-if="application.title">{{ application.title }}</span>
              <span v-else>{{ application.street }}</span>
            </p>
            <p class="light" v-if="application.title">{{ application.street }}</p>
            <p class="light mb-05">
              {{ application.size }} m² | {{ application.bedrooms }} {{ t('common.roomsAbbreviation') }}
            </p>
            <p>{{ application.price }} €/{{ t('common.month') }}</p>
            <div class="applied" v-if="isApplicationCompleted">
              {{ t('applications.applied') }}
              <RouterLink class="icon" :to="{ name: 'applicationDetails', params: { id: application.id } }">
                <IconButton :icon="arrowIcon" border small data-test-id="applicationDetails" />
              </RouterLink>
            </div>
            <RouterLink
              v-else
              :to="{ name: 'tenantsInformation', params: { id: application.id } }"
              data-test-id="applicationLink"
              @click="track(AnalyticsEvent.TenantApplicationSelectApplication)"
            >
              <Button v-if="mustCompleteDocumentation" class="mt-1" variant="failure">
                {{ t('applications.completeDocumentation') }}
              </Button>
              <Button v-else class="mt-1">{{ t('applications.request') }}</Button>
            </RouterLink>
          </ImageCard>
        </template>
        <template v-else>
          <ImageCardSkeleton />
          <ImageCardSkeleton />
          <ImageCardSkeleton />
        </template>
      </div>
    </div>
    <ApplicationInstructions />
  </div>
</template>
<script setup lang="ts">
import arrowIcon from '@/assets/images/icons/arrow_right.svg'
import {
  ApplicationInstructions,
  ApplicationUpdatedModal,
  Button,
  EmptyApplications,
  IconButton,
  ImageCard,
  ImageCardSkeleton,
  TenantAccountSuccessModal,
  TenantEmptyAccountModal
} from '@/components'
import { useAnalytics } from '@/hooks/useAnalytics'
import { AnalyticsEvent } from '@/modules/analytics/domain/analyticsEvent'
import { createApplication, findAllApplications } from '@/modules/application/application'
import type { Application } from '@/modules/application/domain/application'
import { findAllTenants, findTenantLead } from '@/modules/tenant/application'
import { isTenantCompleted, isTenantDocumentationCompleted } from '@/modules/tenant/domain/tenant'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { RouterLink } from 'vue-router'

const { t } = useI18n()
const { track } = useAnalytics()

const applications = ref<Nullable<Application[]>>(null)
const isApplicationCompleted = ref(false)
const mustCompleteDocumentation = ref(false)

track(AnalyticsEvent.TenantApplicationApplicationsList)

async function linkTenantApplication() {
  try {
    const { propertyId } = await findTenantLead()
    if (propertyId) {
      await createApplication(propertyId)
      applications.value = await findAllApplications()
    }
  } catch {}
}

onMounted(async () => {
  const tenants = await findAllTenants()
  isApplicationCompleted.value = tenants.every(isTenantCompleted)
  mustCompleteDocumentation.value =
    tenants.some((tenant) => tenant.employmentStatus !== null) && !tenants.every(isTenantDocumentationCompleted)
  applications.value = await findAllApplications()
  if (applications.value.length === 0) {
    await linkTenantApplication()
  }
})
</script>
<style scoped lang="sass">
@import "@/assets/styles/responsive.sass"

@include lg
  .main
    display: flex
    justify-content: space-between
    align-items: flex-start

    .container
      flex-grow: 1

p:not(.light)
  font-size: 1rem
  font-weight: bold

button.button
  padding: 1rem
  width: 100%

.applied
  display: flex
  justify-content: space-between
  align-items: center
  margin-top: 1.75rem
  padding-top: 1rem
  border-top: 1px solid #E6E6E6
</style>
